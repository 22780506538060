import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Table, Input, Button, UncontrolledCarousel } from "reactstrap";
import axios from 'axios';
import {globals, cGUID, cTimeStamp} from "../Globals/Globals"
import Blob3 from "../Blob3/Blob3";


class FilesRawTableDataSync extends Component{
    constructor(props){
        super(props);
        this.state={media_list:null, currentSession:cGUID()};
    }

    componentDidMount = async () => {
        //Let us fetch media from remote database   
        if (globals.parameters.debugging) {
            console.clear();
            console.log("FilesRawTableDataSync::componentDidMount");
        }

        let l_o_datasync = {
            session_guid:this.state.currentSession, 
            /*
            company_guid:globals.datasync.jlrpt_company_guid, 
            table_guid:globals.datasync.media_table_guid, */
            date_synchro: '1970-07-10 063050'};
        let l_s_sync_object_stringified = JSON.stringify(l_o_datasync);

        await axios.post(
                `${globals.datasync.syncStartUrl}
                &data_sync=${l_s_sync_object_stringified}
                &company_guid=${globals.datasync.jlrpt_company_guid}
                &table_guid=${globals.datasync.media_table_guid}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,''))
                .then(
                    server_answer_as_text => {
                        let server_answer_as_object = server_answer_as_text.data;
                        if ((server_answer_as_object.available_data_count) && (server_answer_as_object.available_data_count > 0)){
                            if (globals.parameters.debugging)
                                console.log("Availables data:"+ server_answer_as_object.available_data_count);
                            //return server_answer_as_object.available_data_count;
                            //Create an array of promises
                            let l_array_PullPromises = [];
                            for (let ii=0; ii < server_answer_as_object.available_data_count; ii++)
                            l_array_PullPromises.push(this._syncPull(l_o_datasync));

                            Promise.all(l_array_PullPromises).then((data_blocks) =>{
                            //Wait for all promises resolution
                            let l_array_medias = []; 
                            data_blocks.forEach((data_block) =>{
                            let l_o_data_block = data_block[0];

                            if (l_o_data_block && l_o_data_block.data_blob){//Protect against null blob items
                                l_o_data_block.data_blob = JSON.parse(l_o_data_block.data_blob);
                                l_o_data_block.data_blob.modified = 0;//Reset action like up to date
                                
                                //process synchronously each asynchonous fetched record 
                                l_array_medias.push(l_o_data_block);
                            }
                            })

                            //Store to state
                            this.setState({media_list:l_array_medias});

                            if (this.props.carousel){
                            //Map to carousel
                            let carouselList = l_array_medias.map((item) => {return {
                                key:item.auto_id, 
                                src:item.data_blob.file_data,
                                altText:'', /*
                                caption:item.data_blob.descritpion, 
                                header:item.data_blob.title}});*/
                                caption:"", 
                                header:""}});

                                if (globals.parameters.debugging){
                                    console.log("carouselList:", carouselList);
                                    console.log("l_array_medias:", l_array_medias);        
                                }

                                this.setState({carouselList:carouselList})
                            }
                            })
                        }
                        else{
                            console.log("Actions: Pas de données disponibles");
                        }
                    }).catch(
                            error => {console.error("Actions: syncStartUrl failed with error:", error);}
                            )
    }

  /*  Prototype : _syncPull
    * Parameter :	JSON object {guid:"Unique synchronisation session id",block_size:"number of data returned in each request/ default return all"
    * Return		:	{remote_data_block:"array of data records"}
    * Purpose 	: Returns prepared required data since given guid session
   *  History   : Created on 2020-01-02
   */ 
  _syncPull(p_o_datasync_guid){
    return new Promise((resolve, reject) => {
      let l_o_datasync = {session_guid:this.state.currentSession, block_size: 1};
      let l_s_sync_object_stringified = JSON.stringify(l_o_datasync);

      fetch(`${globals.datasync.syncPullUrl}
             &data_sync=${l_s_sync_object_stringified}
             &company_guid=${p_o_datasync_guid.jlrpt_company_guid}
             &table_guid=${p_o_datasync_guid.media_table_guid}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')).then(
        results => {return results.text();}
      ).then(
        server_answer_as_text =>{
          let server_answer_as_object = JSON.parse(server_answer_as_text);
          if (server_answer_as_object.remote_data_block){
            this.trace("_syncPull::availables data:"+ server_answer_as_object.remote_data_block.length);
            resolve(server_answer_as_object.remote_data_block);
          }
          else{
            this.trace("_syncPull::failed");
            resolve({remote_data_block:[]});//Return empty array
          }
        }
      )
    })
  }

  trace = (msg) => {
      if (globals.parameters.debugging){
          console.log(msg);
      }
  }

    onEditFileClickHandler = async (p_int_index_in_array) => {        
        //Retrieve selected file id from selection index array
        let selected_idfile = this.state.media_list[p_int_index_in_array].idfile;

        //Broadcast edit mode to parent view
        if (this.props.onEditMedia)
                this.props.onEditMedia(selected_idfile)
    }

    onOpenFileClickHandler = async (p_int_index_in_array) => {        
        //Returns selected file object

        //Trigger Open viewer parent view
        if (this.props.onOpenMedia)
                this.props.onOpenMedia(this.state.media_list[p_int_index_in_array])
    }

    onDeleteFileClickHandler = async (mediaItemId) => {
        try{
            if (globals.parameters.debugging){
                console.clear();
                console.log("onDeleteFileClickHandler");
                console.log("mediaItemId =>",mediaItemId)
                console.log("this.state.media_list[depositItemId] =>",this.state.media_list[mediaItemId])                    
            }

            let l_o_mediaItem = Object.assign({}, this.state.media_list[mediaItemId]); 
        
            const POSTform = new FormData();
      
            l_o_mediaItem.deletestamp = cTimeStamp(); //Set update stamp
          
            l_o_mediaItem.data_blob = JSON.stringify(l_o_mediaItem.data_blob).replace(/"/g, '\\"').replace(/'/g, "''").replace(/\\n/g, '\\\\n');
            let l_s_media_object_stringified = JSON.stringify(l_o_mediaItem);
      
            POSTform.append("action", "syncPush"); //
            POSTform.append("company_guid", globals.datasync.jlrpt_company_guid);
            POSTform.append("table_guid", globals.datasync.media_table_guid);
            POSTform.append("data_sync", l_s_media_object_stringified);
      
            fetch(globals.datasync.syncPushUrl,
                    {method: "POST", body: POSTform}).then(
              results => {return results.text();}
              ).then(
                    sync_server_response => {
                        let json_response = JSON.parse(sync_server_response);
                        if (globals.parameters.debugging)
                            console.log("json_response ->", json_response)
                        if (json_response.sync_push_result.state == true){
                            /* Remove media from media_list */
                            this.setState({media_list: this.state.media_list.filter((item, j) => j !== mediaItemId)}, ()=>{console.log("delete mediaItemId=>",mediaItemId)});
                            alert("Média supprimé")
                        }
                        else{
                            alert(`Erreur de suppression : " ${json_response.sync_push_result.message}"`);
                        }            
                } 
              ).catch(err => {alert(`Erreur de sauvegarde ${err}`)})
      
            return true;
          }
          catch(e){
            alert(e.message)
            return false;
          }
    }

    fileTableRendering = () => {
        let table_body = null;

        if (!((this.state.media_list != null) && (this.state.media_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun Fichier</td></tr>;
        }
        else{
            table_body = this.state.media_list.map((fileItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        {/*<td className="col_logo"><FontAwesomeIcon icon={faEllipsisH} onClick={()=>{this.onDeletefile(parseInt(i,10))}} style={{cursor:"pointer"}}/></td>*/}
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier</Button></td>
                        <td className="col_desc"><p>{fileItem.data_blob.title}</p></td>  
                        <td className="col_desc"><p>{fileItem.data_blob.full_file_name}</p></td>  
                        <td className="col_logo">
                                <Blob3
                                    readOnly={true}
                                    class="pic"
                                    Caption="Charger le média ..." 
                                    data={fileItem.data_blob.file_data}/>
                        </td>
                        <td className="col_desc"><p>{fileItem.data_blob.time_stamp}</p></td>  
                        <td className="col_desc"><p>{fileItem.data_blob.is_enabled=="1"?"Affiché":"Masqué"}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Titre</th>
                        <th>fichier</th>
                        <th>Preview</th>
                        <th>Date</th>
                        <th>Visible</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    fileGridRendering = () => {
        if (((this.state.media_list != null) && (this.state.media_list.length > 0) && (this.props.grid) ))
        return(
            <div className="events-columns-container">
                {
                    this.state.media_list.map((fileItem,i)=>{
                        return  <div key={i} className="event-column" onClick={()=>{this.onOpenFileClickHandler(parseInt(i,10))}}>
                                <p>{fileItem.data_blob.title}</p>
                                        <Blob3
                                            readOnly={true}
                                            class="pic"
                                            Caption="Charger le média ..." 
                                            data={fileItem.data_blob.file_data}/>
                                
                                <p>{fileItem.data_blob.time_stamp}</p>
                            </div>})
                }
            </div>);
    }

    carouselRendering = () => {
        if ((this.state.carouselList != null) && (this.state.carouselList.length > 0) && (this.props.carousel)) 
        return(
            <div className={`${this.props.className}`}>
                <UncontrolledCarousel items={this.state.carouselList} className="actions"/>
            </div>);
    }

    render(){
        return (
            <>
                {this.props.grid  && <>{this.fileGridRendering()}</>}
                {this.props.table && <>{this.fileTableRendering()}</>}
                {this.props.carousel && <>{this.carouselRendering()}</>}
            </>);
    }
}


FilesRawTableDataSync.propTypes = { 
    grid: PropTypes.bool,
    table: PropTypes.bool,
    carousel: PropTypes.bool
};

FilesRawTableDataSync.defaultProps = {
    grid:false,
    table:false,
    carousel:true
};



export default FilesRawTableDataSync;
