import React, { Component } from 'react'
import {Route, NavLink, Switch, BrowserRouter, useNavigate} from "react-router-dom"
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import FlipCountdown from '@rumess/react-flip-countdown';
import DailyWorshipText from '../DailyWorshipText/DailyWorshipText';

import axios from 'axios'
import RubriquesPage from '../RubriquesPage/RubriquesPage'
import NestedTitlePicture from '../NestedTitlePicture/NestedTitlePicture'
import './HomePage.css'

import worship_picture from '../../Assets/img/worship_with_us.jpg'
import {globals} from '../Globals/Globals'
import ReactPlayer from 'react-player';

import AnimatedNumbers from "react-animated-numbers";
import FilesRawTable  from '../FilesRawTable/FilesRawTable';
import SocialShare from '../SocialShare/SocialShare';
import FilesRawTableDataSync from '../FilesRawTableDataSync/FilesRawTableDataSync'

class HomePage extends Component{
    constructor(props){
        super(props)
        this.state = {stats_list:null}
    }

    componentDidMount = async () => {
        //Let us fetch partners from remote database
        let stats = await axios.get(globals.req_stats_endpoint.url);
        this.setState({stats_list : stats.data.items}, ()=>{console.log("state", this.state.stats_list)});
    }

    render = () => {
        return(
            <div className="main-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <div className="nested-title">
                        <div>Jésus est la réponse.</div>
                        <div>Ministère de la croisade</div>
                        {/**
                        <a class="rounded-button transparent-button" href="/dailyworship">{globals.menu.daily_worship}</a>
                         */}
                    </div>
                </div>
                <div className="body-page-container">
                    {/* Welcome Panel */}
                    <div className="welcome-title">
                        <div>
                            Ministère d'intercession et de prières de la Croisade de la Prière.
                        </div>
                    </div>

                    {/*Progress*/}
                    <div className='progress-fluid-container'>
                        <div className='progress-columns-container'>
                            <div className='progress-column'>
                                <AnimatedNumbers
                                    className='animated-container'
                                    animateToNumber={this.state.stats_list?(parseInt(this.state.stats_list[0].cumul) + parseInt(this.state.stats_list[1].cumul)):0}
                                    fontStyle={{ fontSize: 32 }}
                                    configs={(number, index) => {
                                    return { mass: 1, tension: 230 * (index + 1), friction: 140 };
                                    }}/>
                                <div className="progress-sub-title">
                                    requêtes soumises
                                </div>
                            </div>

                            <div className='progress-column'>
                                <AnimatedNumbers
                                    className='animated-container'
                                    animateToNumber={this.state.stats_list?(parseInt(this.state.stats_list[1].cumul)):0}
                                    fontStyle={{ fontSize: 32 }}
                                    configs={(number, index) => {
                                    return { mass: 1, tension: 230 * (index + 1), friction: 140 };
                                    }}/>
                                <div className="progress-sub-title">
                                    requêtes exaucées
                                </div>
                            </div>

                            <div className='progress-column'>
                                <AnimatedNumbers
                                    className='animated-container'
                                    animateToNumber={this.state.stats_list?this.state.stats_list[2].cumul:0}
                                    fontStyle={{ fontSize: 32 }}
                                    configs={(number, index) => {
                                    return { mass: 1, tension: 230 * (index + 1), friction: 140 };
                                    }}/>
                                <div className="progress-sub-title">
                                    remerciements
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* News */}
                    {globals.parameters.top_actu &&
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Top-actualités</div>
                            {/* Latest news */}
                            <div className="news-columns-container">                                
                                {/*Top event*/}
                                <div className="news-column">
                                    <FilesRawTable
                                        readOnly={true}
                                        premium_filter={true}
                                        hide_time_stamp = {true}
                                        hide_title = {true}
                                        /*onOpenMedia={onOpenMedia}*/
                                        />
                                </div>

                                {/*Culte vidéo*/}
                                <div className="news-column">
                                    <ReactPlayer
                                            url="https://www.jlrpt.com/video/soiree_27_nov_2021.mp4"
                                            //className='react-player'
                                            playing={true}
                                            controls={true}
                                            light={"https://jlrpt.com/picture/snap_shot_semaine_de_priere_nov_2021.jpg"}
                                            pip={true}
                                            width='100%'
                                        />
                                    <div className="player-legend">Soirée prière 17-novembre-2021</div>

                                </div>
                            </div>
                        </div>
                    </div>}

                    {/* News Carousel*/}
                    <div className="news-fluid-container grayed">
                        <div className="news-container">
                            <div className="link-with-arrow">Actualités</div>
                            {/* Latest news */}
                            <div className="news-columns-container">                                
                                <div className="news-column">
                                    <FilesRawTableDataSync
                                        className={"events-columns-container"}
                                    />                                    
                                </div>

                                {/*Texte conférence*/}
                                <div className="news-column">
                                <div className="latest-news-teaser">Evènements à venir</div>
                                    <div className="news-text">
                                        <p>
                                        Rejoignez-nous sur Zoom !
                                        <br/> 
                                        </p>
                                        {/*
                                        <b>ID:</b>279&nbsp;507&nbsp;72&nbsp;58
                                        <b>MDP:</b>12&nbsp;10&nbsp;89&nbsp;097</p>*/}
                                    </div>
                                    {/** 
                                    <Button renderAs="button" onClick={()=>{window.open(`https://zoom.us/j/4826323228?pwd=K3NtaWV3dldYS1E4ZExzOS9xS0VhQT09`)}}>
                                        <span>Je rejoins la conférence...</span>
                                    </Button>
                                    <div>
                                        <SocialShare 
                                                url={globals.share.complot.url}
                                                description={globals.share.complot_description}
                                                single={true}
                                                />
                                    </div>*/}

{/***
                                    <a class="rounded-button classic-button" href="/contact">Je Partage ...</a>
                                    <div className="buttons-panel">
                                        <p>Je partage l'évènement&nbsp;!</p>
                                        <SocialShare 
                                            url={globals.share.worship.url}
                                            description={globals.share.worship_description}
                                            />
                                    </div>
                                     */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Worship*/}
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Ellen White : Avec Dieu chaque jour...</div>
                            {/* New assistant */}
                            <div className="news-columns-container">                                
                                {/*Text*/}
                                <div class="news-column">
                                    {/*  Ellen Gould White */}
                                    <DailyWorshipText shorten={true}/>
                                    <a class="rounded-button classic-button" href="/dailyworship">Je lis la suite...</a>
                                </div>

                                <div className="news-column">
                                <div className="latest-news-teaser">Avec Dieu chaque jour</div>
                                    <div className="news-text">
                                        <p>Méditez chaque jour un passage biblique tiré des écrits de <i>Ellen Gould White</i>.</p>
                                        <p> 
                                            Quel que soit votre questionnement, vos doutes, vos épreuves; Nous avons la conviction que&nbsp;
                                            <b>Jésus    est la réponse !</b>
                                        </p>
                                    </div>

                                    <a class="rounded-button classic-button" href="/contact">Je m'abonne ...</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* News Assistant
                    <div className="news-fluid-container grayed">
                        <div className="news-container">
                            <div className="link-with-arrow">Nouveau</div>
                            {/* New assistant 
                            <div className="news-columns-container">                                
                                {/*Challenge vidéo
                                <div class="news-column">
                                    <div className="latest-news-teaser">Assistant de requêtes</div>
                                    <div className="news-text">
                                        <p>Pour vous, nous avons mis au pint un assistant digital, pour vous permettre de soumettre un ou plusieurs sujets de prières en répondant à quelques questions simples.</p>
                                        <p> 
                                            Vous êtes ainsi guidés, pas à pas, jusqu'à la soumission de votre requête de prière.
                                            N'hésitez plus !
                                        </p>
                                    </div>

                                    <Button onClick={(e)=>{onClickAssistantHelper(e)}}>Je soumets par l'assistant</Button>
                                </div>

                                <div className="news-column">
                                    <NestedTitlePicture
                                        height={"350px"} 
                                        pictureTitle={" "}
                                        pictureURL={assistant_picture}
                                        fontSize={"larger"}
                                    />
                                    <div className="player-legend">Assistant de requête de prières.</div>
                                </div>
                            </div>
                        </div>
                    </div>/*}

                    {/* Monthly Challenge
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Témoigner</div>
                            // Latest news 
                            <div className="news-columns-container">                                
                                // Challenge vidéo
                                <div className="news-column">
                                    <ReactPlayer
                                            url="https://www.jlrpt.com/video/challenge_video_06.mp4"
                                            //url="https://www.jlrpt.com/video/challenge_video_05.mp4"
                                            //url="https://www.lacroisadedelapriere.com/video/challenge_video_04.mp4"
                                            //className='react-player'
                                            playing={true}
                                            controls={true}
                                            light={"https://www.jlrpt.com/picture/challenge_video_5.jpg"}
                                            //light={"https://www.lacroisadedelapriere.com/picture/challenge_video_3.jpg"}
                                            pip={true}
                                            width='100%'
                                        />
                                    <div className="player-legend">Bande annonce - challenge #6 - Juin 2022</div>

                                </div>
                            
                                <div class="news-column">
                                    <div className="latest-news-teaser">Challenge vidéo</div>
                                    <div className="news-text">
                                        <p>Le ciel entier est en ébullition totale autour des préparatifs pour le retour glorieux de notre Seigneur <b>Jésus-Christ</b>.
                                            Rendons cette année spéciale en permettant à Dieu d’œuvrer en nous pour accomplir ses desseins.</p>
                                        <p> 
                                            Prêts à relever le <b>6<sup>ème</sup></b> challenge de l’année&nbsp;? Découvrez-le en vidéo&nbsp;!
                                            Pour chaque challenge relevé, témoignons massivement en postant nos photos, nos vidéos, nos récits d’expériences ou autres supports ...
                                        </p>
                                    </div>

                                    <a 
                                        class="rounded-button classic-button" 
                                        href="mailto:la.croisade.de.la.priere@gmail.com">J'envoie mon témoignage...</a>
                                    </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Rubriques de soumissions */}
                    <div className="rubriques-fluid-container">
                        <div className="rubriques-container">
                            <div className="rubriques-title">Confiez-nous vos sujets de prière</div>
                            <p className="rubriques-introduction">
                                Les sujets sont ventilés par rubriques en fonction de leurs natures.
                            </p>
                            <RubriquesPage/>
                        </div>
                    </div>

                    {/* News */}
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Louer le Seigneur</div>
                            {/* Latest news */}
                            <div className="news-columns-container">
                                <div className="news-column">
                                    <div className="latest-news-teaser">Prochain culte zoom</div>
                                    

                                    <div className="countdown-container">
                                        <div className ="countdown-timing">
                                            <FlipCountdown
                                                hideYear
                                                hideMonth
                                                yearTitle='Années'
                                                monthTitle='Mois'
                                                dayTitle='Jours'
                                                hourTitle='Heures'
                                                minuteTitle='Minutes'
                                                secondTitle='Secondes'
                                                endAt={'2023-06-24 19:00:00'} // Date/Time
                                            />
                                        </div>     
                                    </div>

                                    <div className="news-text">
                                        <p>Venez louer le Seigneur, rejoignez <b>La croisade de la prière</b>.
                                        <br/>Nous vous invitons à participer à nos cultes en direct sur Zoom.
                                        <br/>Culte en direct chaque <i>dernier sabbat</i> du mois, Challenge témoignage le <i>1<sup>er</sup> jour du mois</i>, intercessions quotidiennes dès 6h00 <i>chaque matin</i>.</p>
                                    </div>

                                    <Button renderAs="button" onClick={()=>{window.open(`https://zoom.us/j/4826323228?pwd=K3NtaWV3dldYS1E4ZExzOS9xS0VhQT09`)}}>
                                        <span>Assister au culte...</span>
                                    </Button>
                                </div>
                                <div className="news-column">
                                    <NestedTitlePicture
                                        height={"100%"} 
                                        pictureTitle={"Culte en direct"}
                                        pictureURL={worship_picture}
                                        fontSize={"larger"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Verset du jour / Edification */}
                    <div className="verse-fluid-container">
                        <div className="verse-container">
                            <div className="link-with-arrow">Verset de la semaine</div>
                            <div className="verse-columns-container">
                                <div className="verse-column">
                                    {/* Verse reference */}
                                    <div className="verse-ref">{globals.weekly.ref}</div>
                                </div>

                                <div className="verse-column">
                                    {/* Verse text */}
                                    <div className="verse-text">
                                        {globals.weekly.verse}
                                    </div>
                                </div>
                            </div>
                            {/** 
                            <NavLink to="/socialshare">
                                <Button renderAs="button">
                                <span>Je partage...</span>
                                </Button>
                            </NavLink>*/}                           

                            <hr/>
                            <p>Je partage ce verset sur les réseaux sociaux ...</p>
                            
                            <div className="buttons-panel">
                                <SocialShare />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 

export default HomePage;